<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Record</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="row">
          <div class="h-75px col-12 col-sm-6">
            <v-text-field
              v-model="formData.name"
              label="Name"
              clearable
              outlined
              dense
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
          </div>
          <div class="h-75px col-12 col-sm-6">
            <v-autocomplete
              v-model="formData.timezone"
              label="Timezone"
              item-text="text"
              item-value="index"
              :items="serverData.timezones"
              clearable
              outlined
              dense
              hide-details
            ></v-autocomplete>
          </div>
          <div class="h-75px col-12 col-sm-6">
            <v-text-field
              v-model="formData.trn"
              label="TRN"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="h-75px col-12 col-sm-6">
            <v-file-input
              dense
              outlined
              v-model="formData.shipping_label_logo"
              multiple
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              label="Shipping label logo"
              clearable
            ></v-file-input>
          </div>
          <div class="h-75px col-12">
            <v-text-field
              v-model="formData.description"
              label="Description"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
        </div>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      name: null,
      timezone: null,
      trn: null,
      description: null,
      shipping_label_logo: null,
    },
  }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/organization/organizations/create")
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },

    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if ((item[1] && item[0] != "shipping_label_logo") || item[1] == 0) {
          data.append(item[0], item[1]);
        } else if (item[1] && item[0] == "shipping_label_logo") {
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },

    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        // let data = JSON.stringify(this.entCreate);
        ApiService.post(
          "/organization/organizations/store",
          this.convertToFormData(this.formData)
        )
          .then(() => {
            Swal.fire({
              title: "Created",
              text: `Record has been successfully added!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        name: null,
        timezone: null,
        trn: null,
        description: null,
      };
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    nameErrors: function () {
      return this.handleFormValidation("name", this);
    },
  },
};
</script>
